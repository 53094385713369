import React from "react"
import useDateRanges from "./useDateRanges"
import { rebasePNL } from "./useRebasedPNLAxis"
// import rollUp from "./rollUp"
import _ from "lodash"
let PNLContext
const { Provider, Consumer } = (PNLContext = React.createContext())

const PNLProvider = ({ portfolio, children }) => {
  const {
    historicalPnl: { data: pnl },
    slug: portName,
    leverage = 1,
    leverageToDisplay,
    summaryType,
  } = portfolio
  // const pnl_roll_up = useMemo(() => rollUp(pnl), [pnl])

  const pnl_data = summaryType !== "WEEKLY" ? pnl : pnl
  const { DateButtons, rangedData, dateRange } = useDateRanges(pnl_data)
  const rebasedData = rebasePNL(rangedData, leverageToDisplay)

  return (
    <Provider
      value={{
        portName,
        data: _.orderBy(rebasedData, ["period"], ["desc"]),
        leverage,
        leverageToDisplay,
        DateButtons,
        dateRange,
        summaryType,
        pnl,
      }}
    >
      {children}
    </Provider>
  )
}

export { PNLContext, Consumer as PNLConsumer, PNLProvider }
