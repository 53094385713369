import React, { useState, useEffect } from "react"
import { Button, Text, TextField } from "@aws-amplify/ui-react"
import { FaSave } from "react-icons/fa"

const TextInput = ({
  value,
  label,
  handleSubmit,
  placeholder,
  name,
  type,
  isMultiline,
}) => {
  const [newValue, setNewValue] = useState("")

  useEffect(() => {
    setNewValue(value)
  }, [value])

  const handleChange = (e) => setNewValue(e.currentTarget.value)

  return (
    <TextField
      labelHidden={true}
      name={name}
      value={newValue || ""}
      onChange={handleChange}
      placeholder={placeholder}
      type={type}
      isMultiline={isMultiline}
      variation="quiet"
      outerStartComponent={
        <Text style={{ whiteSpace: "nowrap" }}>{label}</Text>
      }
      outerEndComponent={
        <Button
          size="small"
          onClick={() => handleSubmit(newValue)}
          loadingText="saving..."
        >
          <FaSave />
        </Button>
      }
    />
  )
}

export default TextInput
