import React from "react"
import moment from "moment"
import RespText from "../../common/RespText"

// TODO: This feels too complicated needs to be simplified

const firstAndLastDates = (data) => {
  let start_date = moment()
  let end_date = moment()

  let total_months = 3

  if (data) {
    const all_dates = data.map((d) => d.period)
    const first = moment(all_dates[0])
    const last = moment(all_dates[all_dates.length - 1])

    start_date = first.isSameOrBefore(last) ? first.clone() : last.clone()
    end_date = first.isSameOrBefore(last) ? last.clone() : first.clone()
    total_months = end_date.clone().diff(start_date, "months")
  }

  return {
    start_date,
    end_date,
    total_months,
  }
}

const makeDateOptions = (data) => {
  const { start_date, end_date, total_months } = firstAndLastDates(data)

  const allMonths = {
    startDate: start_date.clone().format("YYYY-MM-DD"),
    endDate: end_date.clone().format("YYYY-MM-DD"),
    label: `${total_months}M`,
  }

  const monthToDate = {
    startDate: moment().startOf("month").format("YYYY-MM-DD"),
    endDate: end_date.clone().format("YYYY-MM-DD"),
    label: `MTD`,
  }

  const lastMonth = {
    startDate: end_date.clone().subtract(31, "days").format("YYYY-MM-DD"),
    endDate: end_date.clone().format("YYYY-MM-DD"),
    label: `LM`,
  }
  const yearToDate = {
    startDate: moment().dayOfYear(1).format("YYYY-MM-DD"),
    endDate: end_date.clone().format("YYYY-MM-DD"),
    label: `YTD`,
  }

  const setMonthRange = (num) => ({
    startDate: moment().subtract(num, "months").format("YYYY-MM-DD"),
    endDate: end_date.clone().format("YYYY-MM-DD"),
    label: `L${num}M`,
  })

  const date_options = [
    {
      label: (
        <RespText
          variation="secondary"
          computer={`All (${total_months} Months)`}
          mobile={`All (${total_months})`}
        />
      ),
      date_range: allMonths,
      max_months: total_months,
      slug: "ALL",
    },
    {
      label: (
        <RespText
          variation="secondary"
          computer={`Month To Date`}
          mobile={`MTD`}
        />
      ),
      date_range: monthToDate,
      max_months: total_months,
      slug: "MTD",
    },
    {
      label: (
        <RespText
          variation="secondary"
          computer={`Year to Date`}
          mobile={`YTD`}
        />
      ),
      date_range: yearToDate,
      max_months: total_months,
      slug: "YTD",
    },
    {
      label: (
        <RespText variation="secondary" computer={`Last Month`} mobile={`LM`} />
      ),
      date_range: lastMonth,
      max_months: total_months,
      slug: "LM",
    },
    {
      label: <RespText variation="secondary" computer={`3 M`} mobile={`L3M`} />,
      date_range: setMonthRange(3),
      max_months: 3,
      slug: "L3M",
    },
    {
      label: <RespText variation="secondary" computer={`6 M`} mobile={`L6M`} />,
      date_range: setMonthRange(6),
      max_months: 6,
      slug: "L6M",
    },
    {
      label: (
        <RespText variation="secondary" computer={`12 M`} mobile={`L12M`} />
      ),
      date_range: setMonthRange(12),
      max_months: 12,
      slug: "L12M",
    },
    {
      label: (
        <RespText variation="secondary" computer={`15 M`} mobile={`L15M`} />
      ),
      date_range: setMonthRange(15),
      max_months: 15,
      slug: "L15M",
    },
    {
      label: (
        <RespText variation="secondary" computer={`18 M`} mobile={`L18M`} />
      ),
      date_range: setMonthRange(18),
      max_months: 18,
      slug: "L18M",
    },
  ]

  return {
    total_months,
    date_options,
  }
}

export default makeDateOptions
