/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getRawPnlData = /* GraphQL */ `
  query GetRawPnlData(
    $portfolioName: String
    $untilDate: String
    $endpoint: String
  ) {
    getRawPnlData(
      portfolioName: $portfolioName
      untilDate: $untilDate
      endpoint: $endpoint
    ) {
      data {
        period
        official
        portfolio_value
        daily_return
        cumulative_return
        daily_return_pct
        weekly_return_pct
        cumulative_return_pct
        leverage
        leverage_cash
        realized_leverage
        return_cash
        cumulative_return_cash
        margin_initial
        margin_maintenance
        pct_drawdown
      }
      formats
      summary {
        total_cumulative_return_pct
        avg_daily_return_pct
        avg_calendar_daily_return_pct
      }
    }
  }
`
export const mergedPortfolioChartData = /* GraphQL */ `
  query MergedPortfolioChartData(
    $portSlug: String!
    $range: String
    $benchmarkSlug: String
  ) {
    mergedPortfolioChartData(
      portSlug: $portSlug
      range: $range
      benchmarkSlug: $benchmarkSlug
    ) {
      id
      name
      displayName
      benchmarkDisplayName
      benchmarkSlug
      dataFrequency
      portfolioNames
      portfolioSlugs
      dateOptions
      selectedRange
      slug
      status
      lastSync
      chartData {
        period
        official
        portfolio_value
        daily_return
        cumulative_return
        daily_return_pct
        benchmark_cumulative_return_pct
        cumulative_return_pct
        leverage
        leverage_cash
        realized_leverage
        return_cash
        cumulative_return_cash
        margin_initial
        margin_maintenance
        pct_drawdown
        drawdown_days
        time_to_recovery
        rebased_benchmark_return
        rebased_return
        rebased
        rebased_lev
        abs_return
        meta
      }
      metaData {
        todaysPNL
        monthToDate
        cumulativeReturnCash
        exposure
        cashLevel
        leverageSetting
        marginMaintenance
        marginMaintenancePortfolioValue
        total_cumulative_return_pct
        avg_daily_return_pct
        avg_calendar_daily_return_pct
        total_number_of_pnl_days
      }
      portfolioMeta {
        cashLevel
        impliedEquity
        leverageSetting
        leverage
        leverageToDisplay
        minDollarPositionValueToShow
      }
    }
  }
`
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      username
      name
      email
      slackID
      createdAt
      updatedAt
    }
  }
`
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        username
        name
        email
        slackID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`
export const getPortfolio = /* GraphQL */ `
  query GetPortfolio($id: ID!) {
    getPortfolio(id: $id) {
      id
      name
      groupsCanAccess
      slug
      status
      displayName
      maintenanceMargin
      endpoint
      lastSync
      historicalPnl {
        data {
          period
          official
          portfolio_value
          daily_return
          cumulative_return
          daily_return_pct
          weekly_return_pct
          cumulative_return_pct
          leverage
          leverage_cash
          realized_leverage
          return_cash
          cumulative_return_cash
          margin_initial
          margin_maintenance
          pct_drawdown
        }
        formats
        summary {
          total_cumulative_return_pct
          avg_daily_return_pct
          avg_calendar_daily_return_pct
        }
      }
      leverage
      contractRolls {
        symbol
        name
        roll_date
        roll_days
      }
      isLegacy
      summaryType
      shouldAutoUpdate
      visibleToInvestors
      leverageToDisplay
      dataFrequency
      createdAt
      updatedAt
    }
  }
`
export const listPortfolios = /* GraphQL */ `
  query ListPortfolios(
    $filter: ModelPortfolioFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPortfolios(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        groupsCanAccess
        slug
        status
        displayName
        maintenanceMargin
        endpoint
        lastSync
        historicalPnl {
          data {
            period
            official
            portfolio_value
            daily_return
            cumulative_return
            daily_return_pct
            weekly_return_pct
            cumulative_return_pct
            leverage
            leverage_cash
            realized_leverage
            return_cash
            cumulative_return_cash
            margin_initial
            margin_maintenance
            pct_drawdown
          }
          formats
          summary {
            total_cumulative_return_pct
            avg_daily_return_pct
            avg_calendar_daily_return_pct
          }
        }
        leverage
        contractRolls {
          symbol
          name
          roll_date
          roll_days
        }
        isLegacy
        summaryType
        shouldAutoUpdate
        visibleToInvestors
        leverageToDisplay
        dataFrequency
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`
export const portfolioBySlug = /* GraphQL */ `
  query PortfolioBySlug(
    $slug: String
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPortfolioFilterInput
    $limit: Int
    $nextToken: String
  ) {
    portfolioBySlug(
      slug: $slug
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        groupsCanAccess
        slug
        status
        displayName
        maintenanceMargin
        endpoint
        lastSync
        historicalPnl {
          formats
        }
        leverage
        contractRolls {
          symbol
          name
          roll_date
          roll_days
        }
        isLegacy
        summaryType
        shouldAutoUpdate
        visibleToInvestors
        leverageToDisplay
        dataFrequency
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`
