import {
  Flex,
  Link,
  ScrollView,
  Text,
  useTheme,
  View,
} from "@aws-amplify/ui-react"
import React from "react"
import { NavLink, useAsyncValue } from "react-router-dom"
import CreatePortfolio from "../../Admin/CreatePortfolio/CreatePortfolio"
import DateFormat from "../../common/DateFormat/DateFormat"
import _ from "lodash"
import "./PortfolioList.css"

const PortfolioList = () => {
  const ports = useAsyncValue()
  const { tokens } = useTheme()
  return (
    <ScrollView height="800px">
      <Flex direction="column">
        <CreatePortfolio />
        {_.orderBy(ports, ["name"]).map((port) => (
          <Link
            as={NavLink}
            key={port.id}
            to={`${port.slug}/settings`}
            style={({ isActive }) => ({
              padding: 2,
              backgroundColor: isActive
                ? tokens.colors.background.primary
                : tokens.colors.background.secondary,
            })}
          >
            <Flex justifyContent="space-between">
              <Flex direction="column" gap={1}>
                <Text style={{ whiteSpace: "nowrap" }}>
                  {port.displayName ? port.displayName : port.name}
                </Text>
                <DateFormat date={port.lastSync} />
                <View>{port.groupsCanAccess?.join(", ")}</View>
                <View>{port.shouldAutoUpdate ? "auto" : null} </View>
              </Flex>
              <Flex>
                <View>{port.status}</View>
                <View>{port.visibleToInvestors ? "V" : "N"}</View>
              </Flex>
            </Flex>
          </Link>
        ))}
      </Flex>
    </ScrollView>
  )
}

export default PortfolioList
