import _ from "lodash"

const QUANT_USERGROUP = process.env.REACT_APP_QUANT_GROUPNAME

const getUserInfo = (user) => {
  const groups =
    user && user.signInUserSession
      ? user.signInUserSession.accessToken.payload["cognito:groups"]
      : []

  const inGroup = (name) => (!!groups ? groups.includes(name) : !!groups)

  const access = user.attributes["custom:access"]?.split(",") || []

  return {
    access,
    isQuant: _.indexOf(access, QUANT_USERGROUP) >= 0,
    groups,
    isSuperAdmin: inGroup("SuperAdmin") && inGroup("Admin"),
    isAdmin: inGroup("Admin"),
    isManager: inGroup("Manager"),
    username: user?.username,
    ...user?.attributes,
  }
}

export default getUserInfo
