import { useCallback, useEffect, useState } from "react"
import _ from "lodash"

const getRebasedData = (data, field) => {
  const base = data
    ? data[field] > 0
      ? -data[field]
      : Math.abs(data[field])
    : 0

  return base
}

export const rebasePNL = (data, leverageToDisplay) => {
  const ordered_data = _.orderBy(data, ["period"], ["asc"])
  const first = _.head(ordered_data)
  const leverageField =
    leverageToDisplay === "REALIZED_LEVERAGE"
      ? "realized_leverage"
      : leverageToDisplay === "LEVERAGE_CASH"
      ? "leverage_cash"
      : "leverage"
  const base_pct = getRebasedData(first, "cumulative_return_pct")
  const base_return = getRebasedData(first, "cumulative_return")
  const base_leverage = getRebasedData(first, leverageField)
  return data.map((d) => ({
    ...d,
    rebased_return: d["cumulative_return"] + base_return + first.daily_return,
    rebased: d["cumulative_return_pct"] + base_pct + first.daily_return_pct,
    rebased_lev: d[leverageField] + base_leverage + first[leverageField],
  }))
}

const useRebasedPNLAxis = (pnlData, leverageToDisplay) => {
  const [rebasedData, setData] = useState([])

  const rebasedDataFn = useCallback(() => {
    if (pnlData.length) {
      const rebasedD = rebasePNL(pnlData, leverageToDisplay)
      setData(rebasedD)
    }
  }, [pnlData, leverageToDisplay])

  useEffect(() => {
    rebasedDataFn()
  }, [rebasedDataFn])

  return rebasedData
}

export default useRebasedPNLAxis
