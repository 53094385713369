import React from "react"
import CsvDownloader from "react-csv-downloader"
import { Button, Flex, Text, View } from "@aws-amplify/ui-react"
import { FaDownload } from "react-icons/fa"

const CSVDownload = ({ data, filename, label }) => {
  return (
    <View display={{ base: "none", large: "block" }}>
      <CsvDownloader
        datas={data}
        filename={filename}
        extension=".csv"
        separator=","
      >
        <Button size="small">
          <Flex>
            <Text>CSV {label}</Text>
            <FaDownload />
          </Flex>
        </Button>
      </CsvDownloader>
    </View>
  )
}

export default CSVDownload
