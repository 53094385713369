import React from "react"
import ReactDOM from "react-dom/client"
import "@aws-amplify/ui-react/styles.css"
import "./index.css"
import App from "./App"
// import * as serviceWorker from "./serviceWorker"
import { Amplify } from "aws-amplify"
import { AuthProvider } from "./components/AmplifyAuth/AuthContext"

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: process.env.REACT_APP_AWS_REGION,
    userPoolId: process.env.REACT_APP_AWS_USER_POOL_ID,
    identityPoolId: process.env.REACT_APP_AWS_IDENTITY_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_AWS_APP_CLIENT_ID,
  },
  aws_appsync_graphqlEndpoint: process.env.REACT_APP_AWS_GRAPHQL_ENDPOINT,
  aws_appsync_region: process.env.REACT_APP_AWS_REGION,
  aws_appsync_authenticationType: process.env.REACT_APP_AWS_APPSYNC_AUTH_TYPE,
  API: {
    endpoints: [
      {
        name: "AdminQueries",
        endpoint: process.env.REACT_APP_AWS_ADMIN_QUERIES_ENDPOINT,
      },
    ],
  },
})

const Root = () => {
  return (
    <AuthProvider>
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </AuthProvider>
  )
}

const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(<Root />)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister()
