import moment from 'moment';

export const removeItemfromArray = (array, item) =>{
    let inx = array.indexOf(item);
    return[
        ...array.slice(0, inx),
        ...array.slice(inx + 1)
    ]
  }
const GREEN_COLOR = '#00FF00';
const RED_COLOR = '#FF0000'
export const colorNum = num => num >= 0 ? GREEN_COLOR : RED_COLOR;
export const formatDate = (date, dateFormat="DD-MMM-YY") => moment(date, 'YYYY-MM-DD').format(dateFormat)
const formatUSD = usd => '$' + usd.toLocaleString()
const formatPCT = pct => pct.toFixed(2) + '%'

export const formatCell = (format, value, isMobile=false) => {
    // console.log(format, value)
    const date_format = isMobile ? "DD-MMM" : "DD-MMM-YY"
    switch (format) {
        case 'date':
            return {
                value: formatDate(value, date_format),
                color: 'white',
                format
            }
        case 'USD':
            return {
                value: formatUSD(value),
                color: colorNum(value),
                format
            }
        case 'pct':
            return {
                value: formatPCT(value),
                color: colorNum(value),
                format
            }
        case 'float':
            return {
                value: value.toFixed(2),
                color: colorNum(value),
                format
            }
        case 'string':
            return {
                value,
                color: 'white',
                format
            }
        default:
            return {
                value,
                color: colorNum(value),
                format
            };
    }
}

export const leverageRanking = value => {
    if (value >= 0 && value < 0.25){
        return 0
    } else if (value >= 0.25 && value < 0.5){
        return 1
    } else if (value >= 0.5 && value < 0.75){
        return 2
    } else if (value >= 0.75 && value <= 0.9){
        return 3
    } else if (value > 0.9 && value <= 1.1){
        return 4
    } else if (value > 1.1 && value < 1.6){
        return 5
    } else if (value >= 1.6 && value < 2){
        return 6
    } else if (value >= 2 && value < 2.6){
        return 7
    } else if (value >= 2.6 && value < 3){
        return 8
    } else {
        return 9;
    }
}

const LEVERAGE_COLORS = {
    0: [0, 4, 255], //deep blue
    1: [0, 89, 255], //blue
    2: [0, 204, 255], //light blue
    3: [0, 255, 213], //bluegreen
    4: [0, 255, 21], //green
    5: [157, 255, 0], //olive
    6: [255, 238, 0], //yellow
    7: [255, 145, 0], //orange
    8: [255, 51, 0], //red
    9: [167, 2, 2], //red_dark
} 
export const pnlRowStyle = (leverage, divider) =>{
    const perc = ((leverage / divider) * 100).toFixed()
    const lvg_color = LEVERAGE_COLORS[leverageRanking(leverage)]
    return {
        backgroundImage: `linear-gradient(-90deg, rgba(${lvg_color.join(',')}, 0.25) 0%, rgba(${lvg_color.join(',')}, 0.15) ${perc}%, rgba(0,0,0,0) ${perc}%, rgba(0,0,0,0) 100%)`,
        backgroundPosition: "right"
    }
} 