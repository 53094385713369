import { Auth } from "aws-amplify"
import React, { Suspense } from "react"
import PortfolioList from "../PortfolioList"
import {
  Await,
  Outlet,
  defer,
  json,
  useAsyncError,
  useLoaderData,
} from "react-router-dom"
import { Flex, View } from "@aws-amplify/ui-react"
import { fetchAllPortfolio } from "../lib/portfolio_api"

export const portfolios_loader = async () => {
  try {
    await Auth.currentAuthenticatedUser()
    const portfolios = fetchAllPortfolio()
    return defer({ portfolios })
  } catch (error) {
    if (error.message === "No credentials") {
      console.log(error.message)
      throw json({ message: error.message, error }, { status: 401 })
    }
    throw error
  }
}

const PortfolioError = () => {
  const error = useAsyncError()
  return <h3> {error.message}</h3>
}

const PortfoliosPage = () => {
  const { portfolios } = useLoaderData()
  return (
    <Flex>
      <Flex direction="column" flex={1}>
        <Suspense fallback={<h1>Loading... </h1>}>
          <Await resolve={portfolios} errorElement={<PortfolioError />}>
            <PortfolioList />
          </Await>
        </Suspense>
      </Flex>
      <View flex={4}>
        <Outlet />
      </View>
    </Flex>
  )
}
export default PortfoliosPage
