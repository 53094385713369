import React from "react"
import "./App.css"
import { ThemeProvider } from "@aws-amplify/ui-react"
import { RouterProvider } from "react-router-dom"

import { metagoraTheme } from "./theme"
import router from "./routes/routes"

const App = () => (
  <ThemeProvider colorMode="system" theme={metagoraTheme}>
    <RouterProvider router={router} />
  </ThemeProvider>
)
export default App
