import React from "react"
import { Authenticator } from "@aws-amplify/ui-react"
import components from "./auth_components"
import getUserInfo from "./lib/getUserInfo"

let AuthContext
const { Provider, Consumer } = (AuthContext = React.createContext())

const AuthProvider = ({ children }) => {
  return (
    <Authenticator components={components}>
      {({ signOut, user }) => {
        return (
          <Provider
            value={{
              user,
              userMeta: getUserInfo(user),
              actions: { signOut },
            }}
          >
            {children}
          </Provider>
        )
      }}
    </Authenticator>
  )
}

export { AuthContext, Consumer as AuthConsumer, AuthProvider }
