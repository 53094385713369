import React, { useContext, useEffect, useState } from "react"
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@aws-amplify/ui-react"
import moment from "moment"
import _ from "lodash"
import { formatCell, pnlRowStyle } from "../../../lib/utils"
import { PNLContext } from "../lib/PNLContext"

const PNLTableCell = ({
  format,
  content,
  isMobile = false,
  style,
  ...rest
}) => {
  const { value, color } = formatCell(format, content, isMobile)
  return (
    <TableCell
      className="pnl-cell"
      collapsing
      style={{ ...style, color }}
      {...rest}
    >
      {value}
    </TableCell>
  )
}

const HistoricalPNLTable = () => {
  const { data, leverageToDisplay: levToDisplay } = useContext(PNLContext)
  const [sortAsc, setSortDir] = useState(false)
  const [sortData, setSortData] = useState([])

  useEffect(() => {
    if (data) {
      let sort = sortAsc ? "asc" : "desc"
      let s_data = [...data]
      let historical_data = _.remove(s_data, (d) => moment().isAfter(d.period))
      setSortData(_.orderBy(historical_data, ["period"], [sort]))
    }
  }, [sortAsc, data])

  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell
            as="th"
            className="pnl-header"
            textAlign="right"
            onClick={() => setSortDir(!sortAsc)}
          >
            Period
          </TableCell>
          <TableCell as="th" className="pnl-header" textAlign="right">
            Portfolio Value
          </TableCell>
          <TableCell as="th" className="pnl-header" textAlign="right">
            Daily Return
          </TableCell>
          <TableCell as="th" className="pnl-header" textAlign="right">
            Cumulative Return
          </TableCell>
          <TableCell as="th" className="pnl-header" textAlign="right">
            Daily Return %
          </TableCell>
          <TableCell as="th" className="pnl-header" textAlign="right">
            Cumulative Return %
          </TableCell>
          <TableCell as="th" className="pnl-header" textAlign="right">
            {levToDisplay === "REALIZED_LEVERAGE"
              ? "Realized Leverage"
              : "Leverage"}
          </TableCell>
        </TableRow>
      </TableHead>

      <TableBody>
        {sortData.map(
          (
            {
              period,
              portfolio_value,
              daily_return,
              cumulative_return,
              daily_return_pct,
              cumulative_return_pct,
              leverage,
              realized_leverage,
            },
            index
          ) => {
            return (
              <TableRow key={index} className="pnl_row">
                <PNLTableCell
                  format={"date"}
                  content={period}
                  textAlign={"right"}
                />
                <PNLTableCell
                  format={"USD"}
                  content={portfolio_value}
                  textAlign={"right"}
                />
                <PNLTableCell
                  format={"USD"}
                  content={daily_return}
                  textAlign={"right"}
                />
                <PNLTableCell
                  format={"float"}
                  content={cumulative_return}
                  textAlign={"right"}
                />
                <PNLTableCell
                  format={"float"}
                  content={daily_return_pct}
                  textAlign={"right"}
                />
                <PNLTableCell
                  format={"float"}
                  content={cumulative_return_pct}
                  textAlign={"right"}
                />
                <PNLTableCell
                  format={"string"}
                  content={
                    levToDisplay === "REALIZED_LEVERAGE"
                      ? realized_leverage
                      : leverage
                  }
                  textAlign={"right"}
                  style={pnlRowStyle(
                    levToDisplay === "REALIZED_LEVERAGE"
                      ? realized_leverage
                      : leverage,
                    3
                  )}
                />
              </TableRow>
            )
          }
        )}
      </TableBody>
    </Table>
  )
}

export default HistoricalPNLTable
