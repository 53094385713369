import { Text } from "@aws-amplify/ui-react"
import React from "react"

const RespText = ({
  computer,
  medium,
  mobile,
  onlyMobile,
  tooltip,
  ...rest
}) => {
  return (
    <>
      {onlyMobile && mobile && <Text {...rest}>{mobile}</Text>}

      {!onlyMobile && mobile && (
        <Text display={{ base: "block", large: "none" }} {...rest}>
          <abbr title={tooltip}>{mobile}</abbr>
        </Text>
      )}
      {!onlyMobile && (
        <Text
          display={{ base: "none", medium: "block", large: "none" }}
          {...rest}
        >
          <abbr title={tooltip}>{medium}</abbr>
        </Text>
      )}
      {!onlyMobile && (
        <Text display={{ base: "none", large: "block" }} {...rest}>
          {computer}
        </Text>
      )}
    </>
  )
}

export default RespText
