import React, { useContext } from "react"
import CSVDownload from "../common/CSVDownload"
import { PNLContext } from "./lib/PNLContext"

const HistoricalPNLRollUp = () => {
  const { data: rollUpData } = useContext(PNLContext)
  return (
    <div style={{ paddingBottom: 50 }}>
      <CSVDownload
        data={rollUpData}
        filename="calendar-spread-jan2020-weekly"
        label="roll up"
      />
      <table width="100%">
        <thead>
          <tr>
            <td>Period</td>

            <td>Portfolio Value</td>
            <td>ABS Return</td>
            <td>Cumulative Return</td>
            <td>Cumulative Return Pct</td>

            <td>Return on Trade Level</td>
            <td>Leverage</td>
          </tr>
        </thead>
        <tbody>
          {rollUpData?.map((d) => (
            <tr key={d.period}>
              <td>{d.period}</td>

              <td>${d.portfolio_value.toLocaleString()}</td>
              <td>${d.daily_return.toLocaleString()}</td>
              <td>${d.rebased_return.toLocaleString()}</td>
              <td>{d.rebased.toFixed(2)}%</td>
              <td>{d.return_cash.toFixed(2)}%</td>
              <td>{d.leverage_cash}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default HistoricalPNLRollUp
