import { Auth } from "aws-amplify"
import React, { Suspense } from "react"
import {
  NavLink,
  useLoaderData,
  Outlet,
  Await,
  json,
  defer,
  useAsyncError,
} from "react-router-dom"
import HistoricalPNLSummary from "../../HistoricalPNL/HistoricalPNLSummary/HistoricalPNLSummary"
import { Flex, Heading, Link } from "@aws-amplify/ui-react"
import { getPortfolioBySlug } from "../lib/portfolio_api"

export const portfolio_loader = async ({ params }) => {
  const { portfolioSlug } = params
  try {
    await Auth.currentAuthenticatedUser()
    const portfolio = getPortfolioBySlug(portfolioSlug)
    return defer({ portfolio })
  } catch (error) {
    if (error.message === "No credentials") {
      console.log(error.message)
      throw json({ message: error.message, error }, { status: 401 })
    }
    throw error
  }
}

const PortfolioError = () => {
  const error = useAsyncError()
  return <h3>{error.message}</h3>
}

const PortfolioDetail = () => {
  const { portfolio: port } = useLoaderData()
  return (
    <Suspense fallback={<h1>Loading... </h1>}>
      <Await resolve={port} errorElement={<PortfolioError />}>
        {(port) => (
          <Flex direction="column">
            <Flex>
              <Heading level={2} style={{ whiteSpace: "nowrap" }}>
                {port.name}
              </Heading>
              <HistoricalPNLSummary pnl={port.historicalPnl} />
            </Flex>
            <Flex justifyContent="space-between">
              <Flex>
                <Link as={NavLink} to="historical">
                  Historical Pnl
                </Link>
                {!!port.contractRolls && (
                  <Link as={NavLink} to="contractrolls">
                    Contract Rolls
                  </Link>
                )}
              </Flex>
              <Link as={NavLink} to="settings">
                Settings
              </Link>
            </Flex>
            <Outlet context={{ port }} />
          </Flex>
        )}
      </Await>
    </Suspense>
  )
}

export default PortfolioDetail
