import { createTheme, defaultDarkModeOverride } from "@aws-amplify/ui-react"

export const metagoraTheme = createTheme({
  name: "metagora-theme",
  overrides: [defaultDarkModeOverride],
  tokens: {
    fonts: {
      default: {
        variable: { value: "Lato, sans-serif" },
        static: { value: "Lato, sans-serif" },
      },
    },
  },
})
