import { Button, Flex, Heading, Text } from "@aws-amplify/ui-react"
import React from "react"
import { useState } from "react"
import TextInput from "../../common/TextInput/TextInput"
import usePortfolioActions from "../../../lib/hooks/usePortfolioActions"

const GroupEdit = ({ port }) => {
  const { updatePortfolio } = usePortfolioActions()
  const [formData, setFormData] = useState("")
  const handleSubmit = (value) => {
    updatePortfolio(port.id, {
      groupsCanAccess: [...(port.groupsCanAccess || []), value],
    })
  }
  return (
    <Flex direction="column" flex={1}>
      <Heading level={4}>Groups Can Access</Heading>
      <Flex direction="column">
        <TextInput
          placeholder="Add Group Name"
          value={formData}
          onChange={(e) => setFormData(e.target.value)}
          handleSubmit={handleSubmit}
        />
        {port.groupsCanAccess?.map((group, inx) => (
          <Flex key={inx} justifyContent="space-between">
            <Text>{group}</Text>
            <Button
              onClick={() => {
                updatePortfolio(port.id, {
                  groupsCanAccess: port.groupsCanAccess.filter(
                    (gp) => gp !== group
                  ),
                })
              }}
            >
              X
            </Button>
          </Flex>
        ))}
      </Flex>
    </Flex>
  )
}

export default GroupEdit
