import * as PORT_API from "../../components/Portfolios/lib/portfolio_api"
import archived from "../archived/archived_portfolios.json"
import moment from "moment"
import _ from "lodash"

const parseData = (rawData, schema) => {
  const headings = schema ? _.map(schema, (s) => s[0]) : []
  const formats = schema ? _.map(schema, (s) => s[1]) : []
  const data = rawData
    ? _.map(rawData, (row) => _.zipObject(headings, row))
    : []
  return { data, formats }
}
const parseElementData = (portfolioData) => {
  const { summary, ...pnlData } = portfolioData
  const { data, formats } = parseData(pnlData.data, pnlData.schema)
  return { data, formats, summary }
}

const usePortfolioActions = () => {
  const pullPortfolioData = async (portfolioName, endpoint) => {
    try {
      let rawPNL = await PORT_API.fetchPNLData(portfolioName, endpoint)

      if (rawPNL.data && rawPNL.data.length === 0) {
        if (portfolioName in archived) {
          rawPNL = parseElementData(archived[portfolioName])
        }
      }
      return {
        historicalPnl: rawPNL,
        lastSync: moment().format(),
      }
    } catch (error) {
      console.log(error)
      throw JSON.stringify(error)
    }
  }
  const syncPortfolioElement = async (portId, portfolioName, endpoint) => {
    try {
      const portfolioData = await pullPortfolioData(portfolioName, endpoint)
      await PORT_API.updatePortfolio(portId, portfolioData)
    } catch (error) {
      console.log(error)
      throw JSON.stringify(error)
    }
  }

  const createPortfolio = async ({ name }) => {
    try {
      const portfolioData = await pullPortfolioData(name)
      await PORT_API.newPortfolio({
        name,
        ...portfolioData,
      })
    } catch (error) {
      console.log(error)
      throw JSON.stringify(error)
    }
  }

  const removePortfolio = async (portID) => {
    try {
      await PORT_API.deletePortfolioWithID(portID)
    } catch (error) {
      console.log(error)
      throw JSON.stringify(error)
    }
  }

  const portfolioNamesOptions = async () => {
    try {
      const pNames = await PORT_API.pullPortfolioNames()
      return pNames.map((pn) => ({ text: pn, value: pn, key: pn }))
    } catch (error) {
      console.log(error)
      throw JSON.stringify(error)
    }
  }

  const archivePortfolio = async (portID, untilDate) => {
    try {
      const oldPort = await PORT_API.getPortfolio(portID)
      const rawPNLData = await PORT_API.fetchPNLData(oldPort.name, untilDate)

      // console.log(rawPNLData)

      await PORT_API.newPortfolio({
        name: "Legacy-" + oldPort.name,
        isLegacy: true,
        historicalPnl: rawPNLData,
      })
    } catch (error) {
      console.log(error)
      throw JSON.stringify(error)
    }
  }

  return {
    updatePortfolio: PORT_API.updatePortfolio,
    pullPortfolioNames: PORT_API.pullPortfolioNames,
    portfolioNamesOptions,
    createPortfolio,
    removePortfolio,
    syncPortfolioElement,
    archivePortfolio,
  }
}

export default usePortfolioActions
