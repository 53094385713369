import { Flex, View } from "@aws-amplify/ui-react"
import React, { useContext } from "react"
import { useOutletContext } from "react-router-dom"
// import ViewData from "../../common/ViewData/ViewData"
import HistoricalPNLRollUp from "../HistoricalPNLRollUp"
import HistoricalPNLTable from "../HistoricalPNLTable/HistoricalPNLTable"
import { PNLContext, PNLProvider } from "../lib/PNLContext"
import "../PNL.css"

const HistoricalPNL = () => {
  const { summaryType, DateButtons } = useContext(PNLContext)
  return (
    <View>
      <Flex>
        <DateButtons />
      </Flex>
      <View>
        {summaryType === "WEEKLY" ? (
          <HistoricalPNLRollUp />
        ) : (
          <HistoricalPNLTable />
        )}
      </View>
    </View>
  )
}

const HistoricalPNLPage = () => {
  const { port } = useOutletContext()
  return (
    !!port.historicalPnl && (
      <PNLProvider portfolio={port}>
        <HistoricalPNL />
      </PNLProvider>
    )
  )
}
export default HistoricalPNLPage
