import React, { useContext } from "react"
import { Button, Flex, useTheme } from "@aws-amplify/ui-react"
import { FaHome } from "react-icons/fa"
import NavBtn from "./NavBtn"
import { AuthContext } from "../AmplifyAuth/AuthContext"

const Nav = () => {
  const { actions } = useContext(AuthContext)
  const { tokens } = useTheme()
  const fixedNav = {
    position: "fixed",
    top: 0,
    width: tokens.space.relative.full,
    backgroundColor: tokens.colors.background.primary,
    zIndex: 999,
  }
  return (
    <Flex style={fixedNav} justifyContent="space-between" alignItems="center">
      <Flex>
        <NavBtn to="/">
          <FaHome />
        </NavBtn>
      </Flex>

      <Flex alignItems="center">
        {/* <NavBtn to="users">Users</NavBtn> */}
        <Button variation="link" onClick={actions.signOut}>
          Logout
        </Button>
      </Flex>
    </Flex>
  )
}

export default Nav
