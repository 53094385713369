import React from "react"
import { View } from "@aws-amplify/ui-react"
import { Outlet } from "react-router-dom"
import Nav from "./Nav"

const Layout = () => {
  return (
    <View style={{ marginTop: "2em", padding: 5 }}>
      <Nav />
      <Outlet />
    </View>
  )
}

export default Layout
