import React, { useCallback, useEffect, useState } from "react"
import moment from "moment"
import { Button, Divider, Flex } from "@aws-amplify/ui-react"
import makeDateOptions from "./makeDateOptions"

const useDateRanges = (rawData) => {
  const { total_months, date_options } = makeDateOptions(rawData)

  const default_index = total_months > 2 ? 4 : 0
  const default_option = date_options[default_index]

  const [selectedInx, setSelectedInx] = useState(default_index)
  const [rangedData, setRangedData] = useState([])
  const [dateRange, setDateRange] = useState(default_option)

  const processDates = useCallback(() => {
    if (rawData) {
      if (rawData.length > 10 && rawData[0].period && dateRange.date_range) {
        if (dateRange.date_range.startDate && dateRange.date_range.endDate) {
          const rData = [...rawData].filter(
            (s) =>
              moment(s.period).isSameOrBefore(dateRange.date_range.endDate) &&
              moment(s.period).isSameOrAfter(dateRange.date_range.startDate)
          )
          setRangedData(rData)
        } else {
          setRangedData(rawData)
        }
      } else {
        setRangedData(rawData)
      }
    }
  }, [rawData, dateRange])

  useEffect(() => {
    processDates()
  }, [processDates])

  const DateButtons = () => {
    return (
      rawData?.length > 10 &&
      date_options.map((dOpt, inx) => {
        if (dOpt.max_months > total_months) return null

        const isActive = inx === selectedInx
        return (
          <Flex direction="column" gap={0} key={inx}>
            <Button
              variation="menu"
              style={{ padding: 2 }}
              className={`${isActive ? "active" : ""}`}
              onClick={() => {
                setSelectedInx(inx)
                setDateRange(dOpt)
              }}
            >
              {dOpt.label}
            </Button>
            {isActive && <Divider />}
          </Flex>
        )
      })
    )
  }

  return {
    DateButtons,
    rangedData,
    dateRange,
  }
}

export default useDateRanges
