import { Button, TextField } from "@aws-amplify/ui-react"
import React, { useState } from "react"
import { newPortfolio } from "../../Portfolios/lib/portfolio_api"

const CreatePortfolio = () => {
  const initialState = {
    displayName: "",
    name: "",
    slug: "",
  }
  const [formState, setFormState] = useState(initialState)

  const handleTextChange = (e) => {
    e.preventDefault()
    setFormState({
      ...formState,
      [e.target.name]: e.target.value,
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    newPortfolio({
      name: formState.name,
      displayName:
        formState.displayName !== "" ? formState.displayName : formState.name,
      slug: formState.name.split(" ").join("-"),
    })
    setFormState(initialState)
  }
  return (
    <TextField
      placeholder="Name"
      name="name"
      value={formState.name}
      onChange={handleTextChange}
      variation="quiet"
      outerEndComponent={
        <Button onClick={handleSubmit} disabled={formState.name === ""}>
          Add
        </Button>
      }
    />
  )
}

export default CreatePortfolio
