import { Table, TableBody, TableCell, TableRow } from "@aws-amplify/ui-react"
import React from "react"
// import { Table } from "semantic-ui-react"

const GREEN_COLOR = "#00FF00"
const RED_COLOR = "#FF0000"
export const colorNum = (num) => (num >= 0 ? GREEN_COLOR : RED_COLOR)

const HistoricalPNLSummary = ({ pnl }) => {
  return (
    !!pnl && (
      <Table>
        <TableBody>
          <TableRow>
            <TableCell>
              TCR ({" "}
              <span
                style={{
                  color: colorNum(pnl.summary?.total_cumulative_return_pct),
                }}
              >
                {pnl.summary?.total_cumulative_return_pct}%
              </span>{" "}
              )
            </TableCell>
            {(!!pnl.summary?.avg_daily_return_pct ||
              pnl.summary?.avg_daily_return_pct === 0.0) && (
              <TableCell>
                ADR ({" "}
                <span
                  style={{ color: colorNum(pnl.summary?.avg_daily_return_pct) }}
                >
                  {pnl.summary?.avg_daily_return_pct}%
                </span>{" "}
                )
              </TableCell>
            )}
            <TableCell>
              ADCR ({" "}
              <span
                style={{
                  color: colorNum(pnl.summary?.avg_calendar_daily_return_pct),
                }}
              >
                {pnl.summary?.avg_calendar_daily_return_pct}%
              </span>{" "}
              )
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    )
  )
}

export default HistoricalPNLSummary
