import { API, graphqlOperation } from "aws-amplify"
import * as mutations from "../../../graphql/mutations"
import * as queries from "../../../graphql/queries"

export const pullPortfolioNames = async () => {
  // try {
  //     const { data } = await API.graphql(graphqlOperation(queries.fetchPortfolioNames));
  //     return data.fetchPortfolioNames
  // } catch (error) {
  //     console.log(error)
  // }
}

export const fetchPNLData = async (
  portfolioName,
  endpoint = "",
  untilDate = ""
) => {
  try {
    const { data } = await API.graphql(
      graphqlOperation(queries.getRawPnlData, {
        portfolioName,
        untilDate,
        endpoint,
      })
    )
    const { getRawPnlData } = data
    return getRawPnlData
  } catch (error) {
    console.log(error)
    throw error
  }
}

export const getPortfolio = async (portID) => {
  try {
    const { data } = await API.graphql(
      graphqlOperation(queries.getPortfolio, { id: portID })
    )
    return data.getPortfolio
  } catch (error) {
    console.log(error)
    throw error
  }
}

export const getPortfolioBySlug = async (slug) => {
  try {
    const { data } = await API.graphql(
      graphqlOperation(queries.portfolioBySlug, { slug })
    )
    return data.portfolioBySlug.items[0]
  } catch (error) {
    throw error
  }
}

export const updatePortfolio = async (id, data) => {
  try {
    await API.graphql(
      graphqlOperation(mutations.updatePortfolio, {
        input: {
          id,
          ...data,
        },
      })
    )
  } catch (error) {
    console.log(error)
    throw error
  }
}

export const newPortfolio = async ({ name, ...portData }) => {
  try {
    const { data } = await API.graphql(
      graphqlOperation(mutations.createPortfolio, {
        input: {
          name,
          endpoint: `?name=${name}`,
          shouldAutoUpdate: false,
          ...portData,
        },
      })
    )
    const { createPortfolio } = data
    return createPortfolio
  } catch (error) {
    console.log(error)
    throw error
  }
}

export const deletePortfolioWithID = async (id) => {
  try {
    await API.graphql(
      graphqlOperation(mutations.deletePortfolio, { input: { id } })
    )
  } catch (error) {
    console.log(error)
    throw error
  }
}

export const fetchAllPortfolio = async () => {
  let ports = []

  try {
    async function fetchPorts(nextToken) {
      const { data } = await API.graphql(
        graphqlOperation(queries.listPortfolios, {
          nextToken,
        })
      )

      ports = [...ports, ...data.listPortfolios.items]

      if (data.listPortfolios.nextToken) {
        await fetchPorts(data.listPortfolios.nextToken)
      }
    }
    await fetchPorts()
  } catch (error) {
    throw error
  }

  return ports
}
