import { Auth } from "aws-amplify"
import {
  createBrowserRouter,
  json,
  Navigate,
  useRouteError,
} from "react-router-dom"
import LoadingScreen from "../components/common/LoadingScreen"
import HistoricalPNLPage from "../components/HistoricalPNL/HistoricalPNLPage"
import Layout from "../components/Layout"
import PortfolioDetail, {
  portfolio_loader,
} from "../components/Portfolios/PortfolioDetail/PortfolioDetail"
import PortfoliosPage from "../components/Portfolios/PortfoliosPage"
import PortfolioManager from "../components/Settings/PortfolioManager"
import { portfolios_loader } from "../components/Portfolios/PortfoliosPage/PortfoliosPage"
import { Flex, View } from "@aws-amplify/ui-react"

const auth_loader = async () => {
  try {
    const user = await Auth.currentAuthenticatedUser()
    return json({ user })
  } catch (error) {
    throw error
  }
}

const RouteErrorBoundary = () => {
  const error = useRouteError()
  console.log(error)
  if (error.status === 401) {
    throw error
  }
  return (
    <Flex direction="column">
      <View>Something has gone wrong.</View>
      <pre>{JSON.stringify(error, true, 2)}</pre>
    </Flex>
  )
}

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    loader: auth_loader,
    errorElement: <LoadingScreen />,
    children: [
      {
        path: "portfolio",
        loader: portfolios_loader,
        errorElement: <RouteErrorBoundary />,
        element: <PortfoliosPage />,
        children: [
          {
            path: ":portfolioSlug",
            loader: portfolio_loader,
            element: <PortfolioDetail />,
            children: [
              {
                path: "historical",
                element: <HistoricalPNLPage />,
              },
              {
                path: "settings",
                element: <PortfolioManager />,
              },
              {
                path: "*",
                element: <Navigate to="settings" />,
              },
            ],
          },
        ],
      },
      {
        index: true,
        element: <Navigate to="portfolio" />,
      },
    ],
  },
])

export default router
