/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      username
      name
      email
      slackID
      createdAt
      updatedAt
    }
  }
`
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      username
      name
      email
      slackID
      createdAt
      updatedAt
    }
  }
`
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      username
      name
      email
      slackID
      createdAt
      updatedAt
    }
  }
`
export const createPortfolio = /* GraphQL */ `
  mutation CreatePortfolio(
    $input: CreatePortfolioInput!
    $condition: ModelPortfolioConditionInput
  ) {
    createPortfolio(input: $input, condition: $condition) {
      id
      name
      groupsCanAccess
      slug
      status
      displayName
      maintenanceMargin
      endpoint
      historicalPnl {
        data {
          period
          official
          portfolio_value
          daily_return
          cumulative_return
          daily_return_pct
          weekly_return_pct
          cumulative_return_pct
          leverage
          leverage_cash
          realized_leverage
          return_cash
          cumulative_return_cash
          margin_initial
          margin_maintenance
          pct_drawdown
        }
        formats
        summary {
          total_cumulative_return_pct
          avg_daily_return_pct
          avg_calendar_daily_return_pct
        }
      }
      leverage
      contractRolls {
        symbol
        name
        roll_date
        roll_days
      }
      isLegacy
      summaryType
      shouldAutoUpdate
      visibleToInvestors
      leverageToDisplay
      dataFrequency
      createdAt
      updatedAt
    }
  }
`
export const updatePortfolio = /* GraphQL */ `
  mutation UpdatePortfolio(
    $input: UpdatePortfolioInput!
    $condition: ModelPortfolioConditionInput
  ) {
    updatePortfolio(input: $input, condition: $condition) {
      id
      name
      groupsCanAccess
      slug
      status
      displayName
      maintenanceMargin
      endpoint
      historicalPnl {
        data {
          period
          official
          portfolio_value
          daily_return
          cumulative_return
          daily_return_pct
          weekly_return_pct
          cumulative_return_pct
          leverage
          leverage_cash
          realized_leverage
          return_cash
          cumulative_return_cash
          margin_initial
          margin_maintenance
          pct_drawdown
        }
        formats
        summary {
          total_cumulative_return_pct
          avg_daily_return_pct
          avg_calendar_daily_return_pct
        }
      }
      leverage
      contractRolls {
        symbol
        name
        roll_date
        roll_days
      }
      isLegacy
      summaryType
      shouldAutoUpdate
      visibleToInvestors
      leverageToDisplay
      dataFrequency
      createdAt
      updatedAt
    }
  }
`
export const deletePortfolio = /* GraphQL */ `
  mutation DeletePortfolio(
    $input: DeletePortfolioInput!
    $condition: ModelPortfolioConditionInput
  ) {
    deletePortfolio(input: $input, condition: $condition) {
      id
      name
      groupsCanAccess
      slug
      status
      displayName
      maintenanceMargin
      endpoint
      historicalPnl {
        data {
          period
          official
          portfolio_value
          daily_return
          cumulative_return
          daily_return_pct
          weekly_return_pct
          cumulative_return_pct
          leverage
          leverage_cash
          realized_leverage
          return_cash
          cumulative_return_cash
          margin_initial
          margin_maintenance
          pct_drawdown
        }
        formats
        summary {
          total_cumulative_return_pct
          avg_daily_return_pct
          avg_calendar_daily_return_pct
        }
      }
      leverage
      contractRolls {
        symbol
        name
        roll_date
        roll_days
      }
      isLegacy
      summaryType
      shouldAutoUpdate
      visibleToInvestors
      leverageToDisplay
      dataFrequency
      createdAt
      updatedAt
    }
  }
`
