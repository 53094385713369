import { Link, Text } from "@aws-amplify/ui-react"
import React from "react"
import { NavLink } from "react-router-dom"

const NavBtn = ({ to, children, color = "" }) => {
  const navLinkStyle = ({ isActive }) => ({
    padding: 5,
    backgroundColor: isActive ? color : "",
  })
  return (
    <Link as={NavLink} to={to} style={navLinkStyle}>
      <Text>{children}</Text>
    </Link>
  )
}

export default NavBtn
