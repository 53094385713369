import { Card, Flex, Heading, Text } from "@aws-amplify/ui-react"
import React from "react"
import {
  isRouteErrorResponse,
  Link,
  useLocation,
  useRouteError,
} from "react-router-dom"

const LoadingScreen = () => {
  const error = useRouteError()
  const { pathName } = useLocation()

  if (!isRouteErrorResponse(error) || error.status !== 401) {
    return (
      <Flex height="100vh" justifyContent="center" alignItems="center">
        <Card textAlign="center">
          <Flex direction="column" padding={50}>
            <Heading level={1}>Liver Capital</Heading>
            <Link to={pathName} style={{ color: "white" }}>
              <Text fontSize="1.2em">View Portfolios</Text>
            </Link>
          </Flex>
        </Card>
      </Flex>
    )
  }

  throw error
}

export default LoadingScreen
