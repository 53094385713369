import { Button, Flex, Text } from "@aws-amplify/ui-react"
import React from "react"
import { useOutletContext } from "react-router-dom"
import SelectInput from "../../common/SelectInput"
import TextInput from "../../common/TextInput/TextInput"
import GroupEdit from "./GroupEdit"
import usePortfolioActions from "../../../lib/hooks/usePortfolioActions"
import DateFormat from "../../common/DateFormat/DateFormat"

const PortfolioManager = () => {
  const { port } = useOutletContext()
  const { historicalPnl, ...portfolio } = port
  const { updatePortfolio, syncPortfolioElement, removePortfolio } =
    usePortfolioActions()
  // console.log(port)
  return (
    <Flex direction="column">
      <Text>Portfolio ID: {port.id}</Text>
      <Text>
        <DateFormat date={port.lastSync} />
      </Text>
      <Flex>
        <Flex direction="column" flex={1}>
          <TextInput
            placeholder="Name"
            name="name"
            value={portfolio.name}
            label="Name"
            handleSubmit={(value) => {
              updatePortfolio(portfolio.id, {
                name: value,
              })
            }}
          />
          <TextInput
            placeholder="Display Name"
            name="displayName"
            value={portfolio.displayName}
            label="Display Name"
            handleSubmit={(value) => {
              updatePortfolio(portfolio.id, {
                displayName: value,
              })
            }}
          />
          <TextInput
            placeholder="Slug"
            name="slug"
            value={portfolio.slug}
            label="Slug"
            handleSubmit={(value) => {
              updatePortfolio(portfolio.id, {
                slug: value,
              })
            }}
          />
          <SelectInput
            placeholder="Type"
            name="status"
            value={portfolio.status}
            type="text"
            label="Portfolio Type"
            handleSubmit={(value) => {
              updatePortfolio(portfolio.id, {
                status: value,
              })
            }}
            options={[
              "PRODUCTION",
              "EXPERIMENTAL",
              "MODEL",
              "LEGACY",
              "DRAFT",
            ].map((s) => ({ key: s, value: s, text: s }))}
          />
        </Flex>
        <GroupEdit port={portfolio} />
        <Flex direction="column" gap={1}>
          <Button
            onClick={() =>
              syncPortfolioElement(
                portfolio.id,
                portfolio.name,
                portfolio.endpoint
              )
            }
            isFullWidth={true}
            size="huge"
            className="admin-btn"
            color="green"
          >
            Sync <DateFormat date={portfolio.lastSync} />
          </Button>
          <Button
            onClick={() => {
              updatePortfolio(portfolio.id, {
                shouldAutoUpdate: !portfolio.shouldAutoUpdate,
              })
            }}
          >
            {portfolio.shouldAutoUpdate ? "Should " : "Don't "}Auto Update
          </Button>
          <Button
            onClick={() => {
              updatePortfolio(portfolio.id, {
                visibleToInvestors: !portfolio.visibleToInvestors,
              })
            }}
          >
            {portfolio.visibleToInvestors ? "" : "Not "}Visible To Investors
          </Button>
          <Button
            onClick={() => {
              updatePortfolio(portfolio.id, {
                isLegacy: !portfolio.isLegacy,
              })
            }}
          >
            {portfolio.isLegacy ? "Legacy " : "Active"}
          </Button>
          <Button color="red" onClick={() => removePortfolio(portfolio.id)}>
            Remove
          </Button>
        </Flex>
      </Flex>
      <Flex direction="column">
        <TextInput
          placeholder="Historical Pnl Endpoint"
          name="endpoint"
          value={portfolio.endpoint}
          label="Historical Pnl Endpoint"
          handleSubmit={(value) => {
            updatePortfolio(portfolio.id, {
              endpoint: value,
            })
          }}
        />
        <TextInput
          placeholder="Leverage"
          name="leverage"
          value={portfolio.leverage}
          type="number"
          label="Leverage"
          handleSubmit={(value) => {
            updatePortfolio(portfolio.id, {
              leverage: value,
            })
          }}
        />
        <SelectInput
          placeholder="Leverage To Display"
          name="leverageToDisplay"
          value={portfolio.leverageToDisplay}
          type="text"
          label="Leverage To Display"
          handleSubmit={(value) => {
            updatePortfolio(portfolio.id, {
              leverageToDisplay: value,
            })
          }}
          options={["LEVERAGE", "REALIZED_LEVERAGE", "LEVERAGE_CASH"].map(
            (opt) => ({ key: opt, value: opt, text: opt })
          )}
        />
        <SelectInput
          placeholder="Summary Type"
          name="status"
          value={portfolio.summaryType}
          type="text"
          label="Summary Type"
          handleSubmit={(value) => {
            updatePortfolio(portfolio.id, {
              summaryType: value,
            })
          }}
          options={["WEEKLY", "DAILY"].map((s) => ({
            key: s,
            value: s,
            text: s,
          }))}
        />
      </Flex>
    </Flex>
  )
}

export default PortfolioManager
