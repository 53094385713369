import React, { useState, useEffect } from "react"
import { Button, Flex, SelectField } from "@aws-amplify/ui-react"
import { FaSave } from "react-icons/fa"
const SelectInput = ({
  value,
  label,
  handleSubmit,
  options,
  placeholder,
  name,
}) => {
  const [newValue, setNewValue] = useState("")

  useEffect(() => {
    setNewValue(value)
  }, [value])

  const handleChange = (e) => setNewValue(e.currentTarget.value)

  return (
    <Flex justifyContent="space-between">
      <SelectField
        name={name}
        label={label}
        labelHidden={true}
        onChange={handleChange}
        placeholder={placeholder}
        value={newValue || ""}
        variation="quiet"
        width="100%"
      >
        {options.map((opt) => (
          <option key={opt.key} value={opt.value}>
            {opt.text}
          </option>
        ))}
      </SelectField>
      <Button
        size="small"
        type="submit"
        onClick={() => handleSubmit(newValue)}
        loadingText="saving..."
      >
        <FaSave />
      </Button>
    </Flex>
  )
}

export default SelectInput
